import React from 'react';

export const ComingSoon = ({ style }) => (
  <svg id="b2fa14bb-6cb9-4e5c-8f5a-48aa7e82ff17" xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 1320 1080.5" style={style}>
    <rect width="1320" height="1080" fill="#f5f5f5" />
    <rect y="0.5" width="1320" height="1080" fill="none" />
    <polygon points="140 345.92 140 656.75 0 737.58 0 265.09 91.3 317.8 140 345.92" fill="#fff" opacity="0.496" style={{ isolation: 'isolate' }}/>
    <path d="M0,180.11v1.16l90.3,52.14v216.9L0,502.45v1.16l91.05-52.58.25-.14V232.83Z" fill="#d2d2d2" />
    <circle id="a4f512f7-621e-4b90-a5ca-f8e4e640b01f" data-name="Ellipse 6" cx="784.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="b08b5659-bd46-4bbc-b372-6758465497da" data-name="Ellipse 6-2" cx="803.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="ac7d962c-3453-4580-9551-cf5b43750abd" data-name="Ellipse 6-3" cx="822.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="bcfe0eb4-2de0-4ac8-8e8b-e1f7452b5fcf" data-name="Ellipse 6-4" cx="841.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="aba89a6c-964c-4e01-897b-1e47518f9ada" data-name="Ellipse 6-5" cx="860.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="bb0eac53-595c-48b2-8789-c11cd2ac83ab" data-name="Ellipse 6-6" cx="879.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="bb5f3efe-0412-489c-8f5e-c9b4fa6a4193" data-name="Ellipse 6-7" cx="898.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="ee24ab75-8dad-4206-b1ad-8e936f2daed3" data-name="Ellipse 6-8" cx="917.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="ba9322d9-e063-4063-8176-38e6621e380b" data-name="Ellipse 6-9" cx="936.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="ac9db081-46db-44db-bba2-d61ef406f304" data-name="Ellipse 6-10" cx="955.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="f469b0f4-3c85-42f0-b926-9bff7c1b696a" data-name="Ellipse 6-11" cx="974.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="f372a236-d2ac-42e6-9ced-e7e342ba271e" data-name="Ellipse 6-12" cx="993.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="fe5c138e-8aad-4005-937e-c9d39f2dddbc" data-name="Ellipse 6-13" cx="1012.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="a4600b81-bb2c-44ff-9841-2ef09e1d460c" data-name="Ellipse 6-14" cx="1031.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="f8601059-9895-4a97-b326-73850ccbe2a8" data-name="Ellipse 6-15" cx="1050.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="a7b7f34a-be9d-4560-b635-f95cb83fe39c" data-name="Ellipse 6-16" cx="1069.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="ae402645-4715-4630-9c31-98aa4ae9fc97" data-name="Ellipse 6-17" cx="1088.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="bf050cce-0b12-4773-a84d-1ffd296bad8c" data-name="Ellipse 6-18" cx="1107.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="aef53a50-ec09-4ab4-bd78-74c478ee7aee" data-name="Ellipse 6-19" cx="1126.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="e30357e6-eb90-4a5c-8c27-8fdf797b3d1a" data-name="Ellipse 6-20" cx="1145.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="bf28ebc1-65ff-471e-81c2-c501bafd6a4a" data-name="Ellipse 6-21" cx="1164.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="bec3b543-8df2-4c3b-922a-633fe5cffc78" data-name="Ellipse 6-22" cx="1183.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="b65c9eb5-3963-43e0-9f2b-d404d179a617" data-name="Ellipse 6-23" cx="1202.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="aa38481a-772d-48b2-888d-6ab9ef578904" data-name="Ellipse 6-24" cx="1221.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="e614dcca-5973-4902-9985-450be3a3e8fd" data-name="Ellipse 6-25" cx="1240.52" cy="821" r="1.5"
            fill="#d2d2d2" />
    <circle id="f4d2100c-537e-4ee3-a95d-a32f3f72dfc7" data-name="Ellipse 6-26" cx="784.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="ad15edd9-ac81-4978-bd6f-813790742685" data-name="Ellipse 6-27" cx="803.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="a891522e-05ff-4fad-8012-f8b4ed203474" data-name="Ellipse 6-28" cx="822.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="b9d7ba1e-777d-41c7-ad68-9d252e8b621e" data-name="Ellipse 6-29" cx="841.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="f9077aa6-ec75-4350-aa76-97dd74d24516" data-name="Ellipse 6-30" cx="860.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="bb06587f-f8e6-4eef-828f-97440a59d91a" data-name="Ellipse 6-31" cx="879.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="fd4fd013-2d35-43aa-823a-ca9b6524734c" data-name="Ellipse 6-32" cx="898.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="f9ae71ec-2b20-43d6-99bf-cf989e01dc3c" data-name="Ellipse 6-33" cx="917.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="bdb5970a-45c9-4177-89f8-4af258440230" data-name="Ellipse 6-34" cx="936.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="b720dd8d-4b87-43b3-a8e0-ff42f2dfa720" data-name="Ellipse 6-35" cx="955.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="a939495e-963b-4dc4-a94b-769be6cfd95a" data-name="Ellipse 6-36" cx="974.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="a5782a8d-24bc-4cfd-8667-3028273af728" data-name="Ellipse 6-37" cx="993.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="b7088f1c-c387-4189-a685-70254e765bf5" data-name="Ellipse 6-38" cx="1012.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="a5855256-ca2c-4bb7-b949-28d483c02959" data-name="Ellipse 6-39" cx="1031.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="fc2241be-c3fa-463c-94e1-2daecd2d4d5a" data-name="Ellipse 6-40" cx="1050.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="b94d82f6-f2e7-4696-a4e1-1432369247d4" data-name="Ellipse 6-41" cx="1069.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="f6cf6719-9045-48d5-963f-dd9639d5b261" data-name="Ellipse 6-42" cx="1088.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="a77c0540-d1e6-4638-a5f7-adf9b0132d25" data-name="Ellipse 6-43" cx="1107.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="e617d05d-7250-48d6-ae23-59d67d3fbfd1" data-name="Ellipse 6-44" cx="1126.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="eef4e182-aaea-483d-94d1-0b0c106405f8" data-name="Ellipse 6-45" cx="1145.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="aff0ab5c-c281-4f7a-aff4-6347235c6ad2" data-name="Ellipse 6-46" cx="1164.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="e84f1d48-53c2-4745-b81a-2717b5cf5e79" data-name="Ellipse 6-47" cx="1183.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="e190a8e3-ffe1-453b-b89e-834d9470d83e" data-name="Ellipse 6-48" cx="1202.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="b8c965cd-c5c1-491d-b628-a19d2ea2857c" data-name="Ellipse 6-49" cx="1221.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="a65ab0bb-fd59-4fbd-9ab3-e1215b86bcba" data-name="Ellipse 6-50" cx="1240.52" cy="840" r="1.5"
            fill="#d2d2d2" />
    <circle id="ad5800b8-03ff-4eda-a7f4-f3ec50873445" data-name="Ellipse 6-51" cx="784.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="afc0b9dd-9e08-446f-920f-6c742a67d2cb" data-name="Ellipse 6-52" cx="803.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="abdc7edc-6607-4434-b29b-23b4e58c44bb" data-name="Ellipse 6-53" cx="822.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="b5c7f883-add3-44cf-97c5-e2d934d77b69" data-name="Ellipse 6-54" cx="841.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="a6d97417-5133-45a7-a33f-a8c7ed1d9016" data-name="Ellipse 6-55" cx="860.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="a3096e03-8235-4533-a37a-e9cbd184ad79" data-name="Ellipse 6-56" cx="879.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="a92e7148-8948-461a-96b5-f5aef8d4f6fd" data-name="Ellipse 6-57" cx="898.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="b52c9f6c-e6cd-45b1-b760-32bacba1c258" data-name="Ellipse 6-58" cx="917.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="a75a86bd-2c0a-498a-ade2-82c2c54b0999" data-name="Ellipse 6-59" cx="936.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="aba41844-97e2-490f-99e1-cf4a503b2fb2" data-name="Ellipse 6-60" cx="955.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="a985b2a6-06dc-4f44-94e7-94b73645f09f" data-name="Ellipse 6-61" cx="974.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="bba29851-58b7-4a3a-a621-9752bd5dc05b" data-name="Ellipse 6-62" cx="993.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="e1bfd9fd-4660-4241-bfad-b826ef2f7c5a" data-name="Ellipse 6-63" cx="1012.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="f1879f65-5453-4b63-adbb-4f9832eaabc7" data-name="Ellipse 6-64" cx="1031.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="f84ca4bd-8b5b-4016-9758-b5807358571d" data-name="Ellipse 6-65" cx="1050.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="feb10d2d-1bb8-4d01-949f-a7707b288905" data-name="Ellipse 6-66" cx="1069.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="b726e577-4763-44ff-a812-e5e060a68ad7" data-name="Ellipse 6-67" cx="1088.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="f2c80944-b5b9-4817-849f-b385c1b6a31e" data-name="Ellipse 6-68" cx="1107.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="af01e4e5-7d7d-4a56-a303-fdaa04a35a1b" data-name="Ellipse 6-69" cx="1126.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="ab86f409-8647-4e6a-81d2-a145c8ce5fd6" data-name="Ellipse 6-70" cx="1145.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="ba5b31f3-af52-4b34-bd7b-df0166872403" data-name="Ellipse 6-71" cx="1164.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="a4d2b288-3ad6-4b62-af24-8f67382c0e49" data-name="Ellipse 6-72" cx="1183.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="ed2f7520-4552-442d-92cd-905ddbad0fc0" data-name="Ellipse 6-73" cx="1202.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="a9d3af6f-fef3-4673-9ecf-275d1422a9a2" data-name="Ellipse 6-74" cx="1221.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="eb120fd1-5ddd-4312-b16e-baf356838f05" data-name="Ellipse 6-75" cx="1240.52" cy="859" r="1.5"
            fill="#d2d2d2" />
    <circle id="b94660b6-c2f3-4b86-a5f8-ada76ddcb94b" data-name="Ellipse 6-76" cx="784.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="b56974a8-b493-4e29-bfae-27a5159a0fb6" data-name="Ellipse 6-77" cx="803.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="a525e588-832d-40d7-9669-bf85a62f8ebb" data-name="Ellipse 6-78" cx="822.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="a1046387-7248-46f7-baac-50ab642d152a" data-name="Ellipse 6-79" cx="841.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="e86f1854-4e1a-45b2-bed9-cbfec92a881a" data-name="Ellipse 6-80" cx="860.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="e37c2b5d-2200-4a5a-ab50-54d64af1d2e3" data-name="Ellipse 6-81" cx="879.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="a92877eb-5ed5-454c-9d53-8b6b510d9678" data-name="Ellipse 6-82" cx="898.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="bc9f58a0-0ca9-47b8-8fb0-913d1856eb97" data-name="Ellipse 6-83" cx="917.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="a634a2db-2d30-4f20-92d5-595de446ec18" data-name="Ellipse 6-84" cx="936.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="a877aec5-ea9e-4744-a93c-f6d355394d4e" data-name="Ellipse 6-85" cx="955.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="ec746e0f-4ec2-4f62-a525-90f20d429c59" data-name="Ellipse 6-86" cx="974.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="ee52c769-95bc-4ae2-b6d0-122e5b422ae9" data-name="Ellipse 6-87" cx="993.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="aa36fd88-974a-4e08-b5d7-01e1cd2f5c51" data-name="Ellipse 6-88" cx="1012.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="a5484018-8bd9-4012-b2f6-67b2041067ef" data-name="Ellipse 6-89" cx="1031.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="ab061c59-d57c-4aba-8569-4d6a554d8143" data-name="Ellipse 6-90" cx="1050.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="e31f6016-3d45-4f7c-8b8e-e907e23ad98b" data-name="Ellipse 6-91" cx="1069.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="f66b15a8-305e-4ce6-b19d-e27cf6d3df7c" data-name="Ellipse 6-92" cx="1088.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="ffcaaf99-8109-4a40-b2a2-77b5c978ffd4" data-name="Ellipse 6-93" cx="1107.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="fd95c0ec-c86a-4043-bab5-62b5f67823c0" data-name="Ellipse 6-94" cx="1126.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="b603c083-e400-4483-a2df-98d91d1988e2" data-name="Ellipse 6-95" cx="1145.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="e807189a-0b08-48d6-8cf1-f877f522a7b8" data-name="Ellipse 6-96" cx="1164.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="e3ff56aa-8803-4099-8e09-0b09da82afa3" data-name="Ellipse 6-97" cx="1183.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="f32af9bc-d406-4e17-8e49-d3e7ee6091ad" data-name="Ellipse 6-98" cx="1202.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="be46b91c-2294-4f74-8203-52a60ce83f8f" data-name="Ellipse 6-99" cx="1221.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="b09d93f9-be05-4932-9f6c-671a9085f379" data-name="Ellipse 6-100" cx="1240.52" cy="878" r="1.5"
            fill="#d2d2d2" />
    <circle id="e5a5faf8-9010-4dfa-a0ca-1ddcbc3fa254" data-name="Ellipse 6-101" cx="784.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="a511edcb-3357-4b32-a5b6-e3fa1835a9ee" data-name="Ellipse 6-102" cx="803.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="bace84a5-ae3f-4e3c-ba88-dc84fb415948" data-name="Ellipse 6-103" cx="822.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="a99a7be8-7d7b-4f71-a614-f4ce6b4ee03f" data-name="Ellipse 6-104" cx="841.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="bc53316c-cdd0-4406-94e8-4be84fcf3a26" data-name="Ellipse 6-105" cx="860.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="a41a947f-698b-4aa9-8e68-ec837059e8ee" data-name="Ellipse 6-106" cx="879.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="befa11e0-c72b-4283-a077-2cab8aeee58b" data-name="Ellipse 6-107" cx="898.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="f8c1b1a3-0a44-4ea5-9674-241958c205e0" data-name="Ellipse 6-108" cx="917.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="b007d037-4b09-470b-a5a8-795f3b9a65b2" data-name="Ellipse 6-109" cx="936.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="e7ee591e-13bd-4bfa-a841-433476901d90" data-name="Ellipse 6-110" cx="955.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="f3653149-3730-420a-b1e5-67f06017c36a" data-name="Ellipse 6-111" cx="974.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="a2067e41-d90b-4734-b432-d05505814d5d" data-name="Ellipse 6-112" cx="993.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="e82ab944-7d01-4300-a1c0-b6d5b93118ae" data-name="Ellipse 6-113" cx="1012.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="b98c0e59-01d9-4b09-88d0-d52af729fbf9" data-name="Ellipse 6-114" cx="1031.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="a6137718-f91c-496e-bd64-3dc76b7bef88" data-name="Ellipse 6-115" cx="1050.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="fe0f0586-665f-46b7-bd09-1e0b448f7443" data-name="Ellipse 6-116" cx="1069.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="ae104d13-a154-489f-a1b1-dd7d58af95b6" data-name="Ellipse 6-117" cx="1088.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="e18bb3c5-109a-4321-8afa-e60566162f16" data-name="Ellipse 6-118" cx="1107.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="f25a1d57-1fd3-43f9-86d3-b475fdf4ddea" data-name="Ellipse 6-119" cx="1126.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="a5aee01f-fbe9-47b9-84b6-cf3edb5b3600" data-name="Ellipse 6-120" cx="1145.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="e1ca5b4f-8b9e-4724-bbcd-d9be80cafe2c" data-name="Ellipse 6-121" cx="1164.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="bb37422e-5625-4503-a3c1-f5af9a0a97f5" data-name="Ellipse 6-122" cx="1183.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="a6329ec9-3c15-47ef-a285-74404da5ff61" data-name="Ellipse 6-123" cx="1202.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="b49cf331-1c09-4b35-9f75-55e524f2fd29" data-name="Ellipse 6-124" cx="1221.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="ba0f3605-8d6d-40a1-9e21-24a57d63da19" data-name="Ellipse 6-125" cx="1240.52" cy="897" r="1.5"
            fill="#d2d2d2" />
    <circle id="f5e5db8f-44ed-48f1-bf87-8c0a2530668e" data-name="Ellipse 6-126" cx="784.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="bc8834c3-89ac-4ea4-a96b-5b6178cc6909" data-name="Ellipse 6-127" cx="803.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="e80ac69b-2a08-416c-8af5-c4464590db0b" data-name="Ellipse 6-128" cx="822.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="ef9fe45b-9ff1-4a0f-87de-8da4155aec68" data-name="Ellipse 6-129" cx="841.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="fe3c0e94-caa2-4784-869f-95b9eef3e9c1" data-name="Ellipse 6-130" cx="860.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="b2b88a26-7f0c-49cd-95bc-8a40c7696f17" data-name="Ellipse 6-131" cx="879.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="ab22f9a4-3d79-490b-9cdf-a3ffd08143e5" data-name="Ellipse 6-132" cx="898.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="f2748c28-8b58-476b-b98c-d0fa91976f00" data-name="Ellipse 6-133" cx="917.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="e7cfcb7e-94f1-4979-a581-776bd401acc8" data-name="Ellipse 6-134" cx="936.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="f9596233-62cc-4c49-a9d5-78f6e404b4ca" data-name="Ellipse 6-135" cx="955.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="efd9c192-68bd-404c-9e33-f66f49a38d43" data-name="Ellipse 6-136" cx="974.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="a57d1376-5fbf-49e8-ac26-3fa56fb06af4" data-name="Ellipse 6-137" cx="993.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="f19d9ddd-acbd-4368-8acb-a6a31e36a17b" data-name="Ellipse 6-138" cx="1012.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="b6abddb5-d354-46aa-b24a-40b6cb28c67b" data-name="Ellipse 6-139" cx="1031.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="fcb3e1b1-3387-4eb1-8433-cf147826ce4d" data-name="Ellipse 6-140" cx="1050.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="ffc83320-a331-4943-8669-b1c3e7dd521d" data-name="Ellipse 6-141" cx="1069.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="a38916a5-731b-40d1-a4ba-18cdbe5f691a" data-name="Ellipse 6-142" cx="1088.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="ab6dd1cb-dae6-4f1d-b58c-730f3731383a" data-name="Ellipse 6-143" cx="1107.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="a070e22a-5a10-440f-8d24-2c31b9650dc0" data-name="Ellipse 6-144" cx="1126.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="fe31faa2-def3-438e-8ec5-55f687a77472" data-name="Ellipse 6-145" cx="1145.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="a15d1a50-42a8-4f28-a5ff-699b9ebd540f" data-name="Ellipse 6-146" cx="1164.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="efa567bd-56a0-440e-84c2-4a4f37815510" data-name="Ellipse 6-147" cx="1183.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="ff49304e-003b-4946-8048-209538747a0d" data-name="Ellipse 6-148" cx="1202.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="e7eb8d70-5bf2-4b87-acb1-c69b0c221543" data-name="Ellipse 6-149" cx="1221.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="f6f9691a-ae97-4937-b7bb-835350ca7787" data-name="Ellipse 6-150" cx="1240.52" cy="916" r="1.5"
            fill="#d2d2d2" />
    <circle id="e4206623-4e68-4995-bc27-c94f9712e9cd" data-name="Ellipse 6-151" cx="784.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="fa571085-c0e0-41c2-8978-9ca8ffb284ff" data-name="Ellipse 6-152" cx="803.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="b7402350-b448-43ae-94e1-cf9b99cfbdd8" data-name="Ellipse 6-153" cx="822.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="e2303586-c14c-4727-bcb1-fa1abfd6cf9f" data-name="Ellipse 6-154" cx="841.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="b094b4fb-2657-4351-9a4e-f36270eb9041" data-name="Ellipse 6-155" cx="860.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="b3aef653-db18-4094-b263-e335fb185898" data-name="Ellipse 6-156" cx="879.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="bf45ae64-856b-4d6e-8761-a164dfac59a2" data-name="Ellipse 6-157" cx="898.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="b6d2e8a3-79cb-4192-a94f-62434820044d" data-name="Ellipse 6-158" cx="917.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="baa1d427-25f1-4feb-b311-72d058c78639" data-name="Ellipse 6-159" cx="936.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="a7124d50-caa2-48e0-bb90-6c077f828bfb" data-name="Ellipse 6-160" cx="955.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="b5554bec-c01b-472b-b51b-c8dd6107431c" data-name="Ellipse 6-161" cx="974.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="a424ab80-98f9-48ca-8cae-73d9a89ef544" data-name="Ellipse 6-162" cx="993.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="fd225962-b560-4fe4-8000-4ee08ffddb67" data-name="Ellipse 6-163" cx="1012.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="b0cca60f-801f-470f-95f1-3a478e16f672" data-name="Ellipse 6-164" cx="1031.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="adcd8021-d3c0-4fc7-8031-e754abb19b75" data-name="Ellipse 6-165" cx="1050.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="ab7011cb-de0b-45b5-a8ab-7bc62e560398" data-name="Ellipse 6-166" cx="1069.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="af013526-5095-4346-9905-52a377335c92" data-name="Ellipse 6-167" cx="1088.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="b6f2fb3b-5e91-4963-87f3-8d0da681612a" data-name="Ellipse 6-168" cx="1107.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="f752124d-2855-4fae-9fbe-739a757724d0" data-name="Ellipse 6-169" cx="1126.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="f434e825-a1c8-4342-b381-ae4942b4babb" data-name="Ellipse 6-170" cx="1145.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="b96d3a60-7e29-40cf-bf2f-d9c3c879240f" data-name="Ellipse 6-171" cx="1164.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="b9285c73-f309-4a27-a30d-324646b740d7" data-name="Ellipse 6-172" cx="1183.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="b903bfee-a0a6-4d62-bc86-a934131d0f18" data-name="Ellipse 6-173" cx="1202.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="f5391c76-e823-4342-99c5-6996789914f4" data-name="Ellipse 6-174" cx="1221.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="ae054b8d-e013-435b-8628-2ddc96a583b8" data-name="Ellipse 6-175" cx="1240.52" cy="935" r="1.5"
            fill="#d2d2d2" />
    <circle id="b55c19a4-ba54-46e3-8a49-a9bbdd2d6a93" data-name="Ellipse 6-176" cx="710.52" cy="11.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ba5301c9-7d8d-4e29-a485-cdc638f88c8a" data-name="Ellipse 6-177" cx="729.52" cy="11.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f2ef6431-bd71-4904-94b2-90f0ec9d611f" data-name="Ellipse 6-178" cx="748.52" cy="11.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="bd95aee9-dfd6-4721-b8ba-9237392832a1" data-name="Ellipse 6-179" cx="767.52" cy="11.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="af57aa19-d0cc-4a0e-9fdb-1b3c7d1d9c35" data-name="Ellipse 6-180" cx="786.52" cy="11.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ee1deec9-d38f-40b3-8b52-ac3b6665784c" data-name="Ellipse 6-181" cx="805.52" cy="11.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a89b151b-6a9b-498b-aeab-795b6511ffeb" data-name="Ellipse 6-182" cx="824.52" cy="11.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b82fe0ee-1c86-401e-9cbf-5a9114006d21" data-name="Ellipse 6-183" cx="843.52" cy="11.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a4bf5a7b-dccc-4d4e-bc05-3d0fcb321718" data-name="Ellipse 6-184" cx="862.52" cy="11.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f2a6e81e-12e0-498d-8e2e-ea71ed3c394e" data-name="Ellipse 6-185" cx="710.52" cy="30.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ec352f6a-eff7-4379-9e74-ef745901c79d" data-name="Ellipse 6-186" cx="729.52" cy="30.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a23aa174-521c-4774-b3ab-7c81bc66affa" data-name="Ellipse 6-187" cx="748.52" cy="30.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ad3a9ea1-c737-4345-94f2-11ca76e853f6" data-name="Ellipse 6-188" cx="767.52" cy="30.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f479d473-1f4a-41c7-bcef-5fd259bb287f" data-name="Ellipse 6-189" cx="786.52" cy="30.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a84a8a77-b8cc-4925-b1e5-72fc495ca934" data-name="Ellipse 6-190" cx="805.52" cy="30.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="be8240cf-e7fd-4d50-a505-7c13a08cbdf0" data-name="Ellipse 6-191" cx="824.52" cy="30.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ba972f7c-8d98-449c-b4d4-292497d7abf1" data-name="Ellipse 6-192" cx="843.52" cy="30.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a29d0ae9-2132-4f55-a190-5825ddf10b9a" data-name="Ellipse 6-193" cx="862.52" cy="30.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f2fad731-516e-46dd-a068-4c1e8e58566e" data-name="Ellipse 6-194" cx="710.52" cy="49.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ea04faf1-1972-4937-885c-7a66f5dc92fe" data-name="Ellipse 6-195" cx="729.52" cy="49.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b14e6763-cc1e-48c0-853a-6a68af7592c9" data-name="Ellipse 6-196" cx="748.52" cy="49.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a1f5cf1a-4976-45a1-9fa8-8bfd8123587c" data-name="Ellipse 6-197" cx="767.52" cy="49.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="e9bea8c1-953d-4f1e-98d9-7c3d41d7e492" data-name="Ellipse 6-198" cx="786.52" cy="49.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b808916a-75ba-46a0-889f-356a180629f1" data-name="Ellipse 6-199" cx="805.52" cy="49.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f485d503-066f-499b-8af7-76a2178aabe6" data-name="Ellipse 6-200" cx="824.52" cy="49.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b13798ad-0f72-4e79-aca9-c1ba13552a1f" data-name="Ellipse 6-201" cx="843.52" cy="49.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a64351e4-ba3a-477f-b882-acc2bfd9e43c" data-name="Ellipse 6-202" cx="862.52" cy="49.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ebd57f23-8078-4c3c-905a-ac4203c7f64c" data-name="Ellipse 6-203" cx="710.52" cy="68.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="af16b820-f837-4b25-bfc6-706184a801db" data-name="Ellipse 6-204" cx="729.52" cy="68.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ac347b8b-c420-4f00-8454-0e955145e959" data-name="Ellipse 6-205" cx="748.52" cy="68.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f7caa6f7-eec7-46ec-a807-7e7226c42381" data-name="Ellipse 6-206" cx="767.52" cy="68.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ecadc0eb-d407-4fa7-9e83-5210cf743f3e" data-name="Ellipse 6-207" cx="786.52" cy="68.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="fc295e6d-ec6c-4ff1-84c2-fb550a8d123a" data-name="Ellipse 6-208" cx="805.52" cy="68.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a0e9cf4b-dc91-4cde-939c-cb297e476457" data-name="Ellipse 6-209" cx="824.52" cy="68.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="aaa6d7c8-10b3-494e-b5c8-7927bcfc5224" data-name="Ellipse 6-210" cx="843.52" cy="68.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a6578c29-e942-4d25-a1ae-ebc5d0c914ed" data-name="Ellipse 6-211" cx="862.52" cy="68.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f86d3fb6-0e20-4cd3-9c97-4a66abe43c90" data-name="Ellipse 6-212" cx="710.52" cy="87.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a51e9816-fde9-4ef9-ad02-f15a97e36447" data-name="Ellipse 6-213" cx="729.52" cy="87.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="aa1b6983-e4c7-4751-8d8c-3e095049b14b" data-name="Ellipse 6-214" cx="748.52" cy="87.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b2fc070b-44cc-42a1-87de-fd9e343427ea" data-name="Ellipse 6-215" cx="767.52" cy="87.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a577f41a-8874-4440-9d4b-5db7e989b194" data-name="Ellipse 6-216" cx="786.52" cy="87.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a928732e-6e5b-4334-a2fc-71b21f25001e" data-name="Ellipse 6-217" cx="805.52" cy="87.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ee36c630-7117-4f34-b4b4-73d92f24001a" data-name="Ellipse 6-218" cx="824.52" cy="87.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f1eda8dd-0d91-428c-be13-e782bb015d20" data-name="Ellipse 6-219" cx="843.52" cy="87.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b8f230c5-bf4e-409a-80bd-2e1828570307" data-name="Ellipse 6-220" cx="862.52" cy="87.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="bc038a37-440c-47ea-9698-e40de397cabe" data-name="Ellipse 6-221" cx="710.52" cy="106.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a3664247-f532-4eb1-81a7-532cfea3779a" data-name="Ellipse 6-222" cx="729.52" cy="106.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f0624977-c4a0-4946-9344-8a8fc217282b" data-name="Ellipse 6-223" cx="748.52" cy="106.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="e0b93726-d3dd-414a-aea4-e3c112942e12" data-name="Ellipse 6-224" cx="767.52" cy="106.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="bbf1265f-1506-442a-a821-fd6fdfee48fc" data-name="Ellipse 6-225" cx="786.52" cy="106.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="adfc5641-59b5-472a-a665-1e9dde464509" data-name="Ellipse 6-226" cx="805.52" cy="106.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a8394652-33cc-4120-a5ab-f3e89c2ef54a" data-name="Ellipse 6-227" cx="824.52" cy="106.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f5f336c6-d2e3-498e-b29a-84796122ea1b" data-name="Ellipse 6-228" cx="843.52" cy="106.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a1d924c9-3cc4-4681-86d0-71d1afc0cd09" data-name="Ellipse 6-229" cx="862.52" cy="106.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="e15f1d8f-2578-464d-9ac4-d471ca33e993" data-name="Ellipse 6-230" cx="710.52" cy="125.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="bae76e73-6628-4995-95d3-1eafc329b511" data-name="Ellipse 6-231" cx="729.52" cy="125.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="bbba3403-c16c-4f3c-b36f-229b7900ca3c" data-name="Ellipse 6-232" cx="748.52" cy="125.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f01959d0-f7b5-4a05-8cac-c6d5e86a2cef" data-name="Ellipse 6-233" cx="767.52" cy="125.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="bf4aaad8-8ca7-4cfc-a197-01f274681024" data-name="Ellipse 6-234" cx="786.52" cy="125.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="bde5be59-a2ac-4e66-af58-3ee5035e2d1d" data-name="Ellipse 6-235" cx="805.52" cy="125.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f1c4fec0-c8e2-45c0-b9ac-c989c4310687" data-name="Ellipse 6-236" cx="824.52" cy="125.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="fa13ce3b-446d-4cb7-a371-a0abdbf76c61" data-name="Ellipse 6-237" cx="843.52" cy="125.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b7dd5a4f-c5c3-4ef4-a38f-ac5b25a11e88" data-name="Ellipse 6-238" cx="862.52" cy="125.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="e86065e6-6a05-4ebb-bb10-19e1b4e73972" data-name="Ellipse 6-239" cx="710.52" cy="144.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ac3d4f33-0e54-4dcb-abfd-1ed97a8f3050" data-name="Ellipse 6-240" cx="729.52" cy="144.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b9485110-f751-410c-af1c-6f8ad4f50cf4" data-name="Ellipse 6-241" cx="748.52" cy="144.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="eab3f62e-d055-4413-a2a7-964057fc41da" data-name="Ellipse 6-242" cx="767.52" cy="144.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="be2a4ea0-fad4-4eb6-adf3-309b02b83cdc" data-name="Ellipse 6-243" cx="786.52" cy="144.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="e32336d0-27d6-4035-91e3-65b2d800d6af" data-name="Ellipse 6-244" cx="805.52" cy="144.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="fb95dd73-a389-447b-9865-ebbf3fb7a4f0" data-name="Ellipse 6-245" cx="824.52" cy="144.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a722f20c-72f6-4480-9fcb-914afac052a4" data-name="Ellipse 6-246" cx="843.52" cy="144.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a034a5f9-8507-46dd-b829-865b8f715e00" data-name="Ellipse 6-247" cx="862.52" cy="144.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a00c73b7-4ef1-4f95-8524-258d938cdce6" data-name="Ellipse 6-248" cx="710.52" cy="163.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ef96e0a4-3285-4f4c-a23c-59a351729425" data-name="Ellipse 6-249" cx="729.52" cy="163.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b75f35c9-5c6f-40cb-b96e-7e688c32ea06" data-name="Ellipse 6-250" cx="748.52" cy="163.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a205332d-d3c7-41db-aabd-f26d14eec2cf" data-name="Ellipse 6-251" cx="767.52" cy="163.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b4c59feb-82b2-4b66-8c66-4751c2436bd1" data-name="Ellipse 6-252" cx="786.52" cy="163.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a8758ea4-cbf0-4763-90d6-9c448519f676" data-name="Ellipse 6-253" cx="805.52" cy="163.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="e42fac61-6146-4110-83ed-daab810e39cb" data-name="Ellipse 6-254" cx="824.52" cy="163.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a41fd1f8-71c4-4f2f-b80c-1dbfa8b70264" data-name="Ellipse 6-255" cx="843.52" cy="163.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="eb3de249-d4c3-494a-b03c-0d162b2fc50b" data-name="Ellipse 6-256" cx="862.52" cy="163.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b52f7874-d2c9-4b79-9fbd-d17ab118b4c4" data-name="Ellipse 6-257" cx="710.52" cy="182.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a715cce3-fa3d-4e58-ae39-dd40d6152137" data-name="Ellipse 6-258" cx="729.52" cy="182.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b216ef6b-f54f-4942-9b68-fc343706ce5a" data-name="Ellipse 6-259" cx="748.52" cy="182.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="fa3e2f09-5fea-4ffa-b246-2bdbfe0872bb" data-name="Ellipse 6-260" cx="767.52" cy="182.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="bd80cfa2-9b8e-474d-adf2-6b8687ebbbda" data-name="Ellipse 6-261" cx="786.52" cy="182.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="be699616-fd9d-4ea4-858c-e4f26dc1ae03" data-name="Ellipse 6-262" cx="805.52" cy="182.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="af6113ef-10fc-49b4-b1cd-74481df94fdd" data-name="Ellipse 6-263" cx="824.52" cy="182.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a17fd948-ef68-4c02-8ecf-6c9267e57196" data-name="Ellipse 6-264" cx="843.52" cy="182.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b11f1235-54e9-42e9-8e15-0e1779e553b4" data-name="Ellipse 6-265" cx="862.52" cy="182.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="fe651885-9f65-4180-9714-d6a68efd087c" data-name="Ellipse 6-266" cx="710.52" cy="201.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a2520e74-f759-4e6c-8f32-4ca3e92ad0fd" data-name="Ellipse 6-267" cx="729.52" cy="201.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="abd606c1-7964-4b6f-bebb-7cf784668746" data-name="Ellipse 6-268" cx="748.52" cy="201.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ff2ecaa0-b17f-4b70-8f28-18e0cfd2169d" data-name="Ellipse 6-269" cx="767.52" cy="201.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a98d3245-6a6e-46e0-8cd6-a7c0a4464176" data-name="Ellipse 6-270" cx="786.52" cy="201.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f42b82e4-0253-4efb-b1eb-531c956b7cb9" data-name="Ellipse 6-271" cx="805.52" cy="201.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f57b1b5e-a2f0-4e63-9033-4e0a31e01604" data-name="Ellipse 6-272" cx="824.52" cy="201.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b8d3921d-b7c0-41fa-b628-5377128efdc2" data-name="Ellipse 6-273" cx="843.52" cy="201.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ee4b63a3-7264-4ad5-9da0-9d26df1bfef4" data-name="Ellipse 6-274" cx="862.52" cy="201.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="bc2dce3c-bb86-470e-8a83-78691e1638e9" data-name="Ellipse 6-275" cx="710.52" cy="220.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="eabf5193-b4eb-430e-a3f9-f7f7a1130bf8" data-name="Ellipse 6-276" cx="729.52" cy="220.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b1f149c8-fd9e-4244-83c3-182483d523a0" data-name="Ellipse 6-277" cx="748.52" cy="220.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a4edcf4c-7e42-4a14-86ff-782b0f540f18" data-name="Ellipse 6-278" cx="767.52" cy="220.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ae43d7d4-b95a-4898-a7df-588a8b811ae4" data-name="Ellipse 6-279" cx="786.52" cy="220.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a685d63f-3383-4ef9-8762-3d004cd8a713" data-name="Ellipse 6-280" cx="805.52" cy="220.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b1cf9124-a4c5-4cf4-8e11-afbb49f4e7d4" data-name="Ellipse 6-281" cx="824.52" cy="220.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b3bb0551-73dc-401c-9f45-2d80f4ef1049" data-name="Ellipse 6-282" cx="843.52" cy="220.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="eac4f22d-20f3-433c-9d3a-29e34ee3bffc" data-name="Ellipse 6-283" cx="862.52" cy="220.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="f5ee9297-bfd5-4633-b0e5-2536e6141602" data-name="Ellipse 6-284" cx="710.52" cy="239.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="aae92e34-b3e6-4cd1-aa81-7aba2f5f35a7" data-name="Ellipse 6-285" cx="729.52" cy="239.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ac6b6362-97d4-4684-90ef-e734f3b2210d" data-name="Ellipse 6-286" cx="748.52" cy="239.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="bf8591b7-143b-45f9-bde4-72241cdf6cc1" data-name="Ellipse 6-287" cx="767.52" cy="239.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b7c0b732-f1ca-42f6-afa4-76982de5a709" data-name="Ellipse 6-288" cx="786.52" cy="239.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="e13b4737-8b54-469b-b0bd-3614477a6cfe" data-name="Ellipse 6-289" cx="805.52" cy="239.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="ec0a4b37-d780-49be-a9c4-38cfac1ddd3c" data-name="Ellipse 6-290" cx="824.52" cy="239.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="e3d5ccc1-30e3-47d2-a263-8b2a9b084105" data-name="Ellipse 6-291" cx="843.52" cy="239.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b446494a-cd3f-4c8f-a974-d88268f5b040" data-name="Ellipse 6-292" cx="862.52" cy="239.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b2402ebc-9735-4622-a607-a16366c8b05b" data-name="Ellipse 6-293" cx="710.52" cy="258.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="efe82cd4-e21d-4746-9707-66a854d5b7a1" data-name="Ellipse 6-294" cx="729.52" cy="258.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a612d2f1-abd5-49fa-aaaa-39e411e1288a" data-name="Ellipse 6-295" cx="748.52" cy="258.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a0fe677e-744d-438c-8241-a86b4f26bccb" data-name="Ellipse 6-296" cx="767.52" cy="258.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b93709fa-3e37-465f-be48-15b028a119c2" data-name="Ellipse 6-297" cx="786.52" cy="258.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="bf8d3261-733a-4dd6-bad7-35e3885a9c7d" data-name="Ellipse 6-298" cx="805.52" cy="258.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b071fe9f-a8a4-46f8-a1d6-f27403b05863" data-name="Ellipse 6-299" cx="824.52" cy="258.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="af444b89-ad22-4025-a73b-d2e08fc09a0d" data-name="Ellipse 6-300" cx="843.52" cy="258.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="e26e30ac-4dbd-43ce-a673-79e6c00f20bd" data-name="Ellipse 6-301" cx="862.52" cy="258.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b250c684-a322-495c-bf23-00798c7f843b" data-name="Ellipse 6-302" cx="710.52" cy="277.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b7ce9004-791d-46e8-a257-01e726400f5b" data-name="Ellipse 6-303" cx="729.52" cy="277.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a8da6022-01f2-46d4-8bf9-b9f34c3326d5" data-name="Ellipse 6-304" cx="748.52" cy="277.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="b7002c74-d4a4-409c-b5ae-12a8f4157c5f" data-name="Ellipse 6-305" cx="767.52" cy="277.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a8cd45b0-3b25-49e8-a6f8-31777b170f2d" data-name="Ellipse 6-306" cx="786.52" cy="277.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="e37a791a-56e6-46da-b617-2bd091800a72" data-name="Ellipse 6-307" cx="805.52" cy="277.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a5891ba6-a267-488a-94ec-0f1cbd233141" data-name="Ellipse 6-308" cx="824.52" cy="277.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a7baf5fb-c1e6-4c8b-ba8d-fe12c4f51748" data-name="Ellipse 6-309" cx="843.52" cy="277.74" r="1.5"
            fill="#d2d2d2" />
    <circle id="a3e396ef-595e-4b61-a99c-ff96ae500d6c" data-name="Ellipse 6-310" cx="862.52" cy="277.74" r="1.5"
            fill="#d2d2d2" />
  </svg>
)
