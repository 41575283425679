import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField'
import { createTheme, Hidden, responsiveFontSizes, ThemeProvider, useMediaQuery } from '@mui/material';
import * as animation from './animation.json';
import Manrope from './Manrope.ttf';
import { WBLogo } from './wb-logo';
import { ComingSoon } from './coming-soon';

const theme = createTheme({
  palette: {
    primary: {
      light: '#B35CF2',
      main: '#5811C1',
      dark: '#5811C1'
    }
  },
  typography: {
    fontFamily: 'Manrope'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Manrope';
          src: local('Manrope'), url('.${Manrope}') format('truetype');
          font-weight: 400;
          font-style: normal;
        }
      `
    }
  }
});

const regex = new RegExp(/^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/);

export const App = () => {
  const [form, setForm] = useState({
    error: false,
    helperText: '',
    value: ''
  });
  const [errorMsg, setErrorMsg] = useState('');
  const [submitted, setSubmitted] = useState('initial');
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleOnClick = () => {
    if (form.error) {
      return;
    }

    if (form.value === '') {
      return setForm((prevState) => ({
        ...prevState,
        error: true,
        helperText: 'Please enter your email.'
      }))
    }

    if (!form.value.match(regex)) {
      return setForm((prevState) => ({
        ...prevState,
        error: true,
        helperText: 'Please enter a valid email.'
      }))
    }

    fetch(
      `https://zkydsye659.execute-api.us-east-1.amazonaws.com/mail-subscribe-prod?email=${form.value}`,
      { method: 'GET' }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      })
      .then(() => {
        setSubmitted('success');
      })
      .catch((e) => {
        e.json()
          .then(({ error }) => {
            setForm((prevState) => ({
              ...prevState,
              error: true,
              helperText: error
            }))
          })
          .catch(() => {
            setSubmitted('error');
            setErrorMsg('Something went wrong.')
          })
      })
  };

  const handleOnChange = (event) => {
    const { value } = event.target;

    setForm((prevState) => ({ ...prevState, error: false, helperText: '', value }));
  }

  useEffect(() => {
    setTimeout(() => {
      setErrorMsg('');
      setSubmitted('initial');
    }, 3000)
  }, [errorMsg]);

  const updatedTheme = responsiveFontSizes(theme);
  updatedTheme.typography.body1.fontSize = '1.1rem';
  updatedTheme.typography.caption.fontSize = '0.95rem';

  return (
    <ThemeProvider theme={updatedTheme}>
      <CssBaseline />
      <Container
        component="main"
        disableGutters
        maxWidth={false}
        sx={{
          backgroundColor: theme.palette.grey[100],
          height: '100vh',
        }}>
        <Hidden smDown>
          <ComingSoon style={{ position: 'absolute', height: '100%', left: 0 }} />
        </Hidden>
        {matches && (
          <Box display="flex" justifyContent="space-between" padding={0}>
            <Lottie
              style={{ margin: 0, height: '100vh' }}
              options={{
                loop: true,
                autoplay: true,
                animationData: animation,
                rendererSettings: {
                  preserveAspectRatio: 'xMaxYMid meet'
                }
              }}
              isStopped={false}
              isPaused={false}
            />
          </Box>
        )}

        <Box
          sx={{
            height: '100vh',
            width: '100%',
            marginTop: { xs: 0, sm: '-30px' },
            position: { xs: 'inherit', sm: 'absolute' },
            top: 0, left: 0
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
              py: { xs: 2, sm: 12 },
              px: { xs: 2, sm: 0 },
              mx: { xs: 0, sm: '9%' },
              maxWidth: '775px',
              maxHeight: { xs: '600px', sm: '850px' },
              height: '100%'
            }}
          >
            <Box sx={{ position: { xs: 'sticky', sm: 'inherit' }, top: { xs: 16, sm: 'inherit' } }}>
              <WBLogo />
            </Box>

            <Box>
              <Typography
                variant="h2"
                component="h1"
                gutterBottom
                sx={{ fontFamily: 'Manrope' }}
              >
                Coming soon
              </Typography>
              <Typography variant="body1">
                Blockchain-based investment platform that offers flexible fund-raising opportunities for
                companies and
                easy investment options for end users.
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography variant="caption" mb={1.5} color="text.secondary">
                To get notified when we are live, please subscribe to our mailing list.
              </Typography>

              {submitted === 'success' && (
                <Grid item xs={12} sm={8}>
                  <Alert variant="outlined" severity="success">
                    You are now subscribed. Thank you!
                  </Alert>
                </Grid>
              )}

              {submitted === 'error' && (
                <Grid item xs={12} sm={8}>
                  <Alert variant="outlined" severity="error">
                    {errorMsg}
                  </Alert>
                </Grid>
              )}

              {submitted === 'initial' && (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={5}>
                    <TextField
                      {...form}
                      fullWidth
                      label="Email"
                      type="outlined"
                      size="small"
                      InputProps={{
                        sx: { backgroundColor: theme.palette.grey[100] },
                        style: { fontSize: '1rem', height: '42px' }
                      }}
                      onChange={handleOnChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} sx={{ height: { xs: 'inherit', sm: '100%' } }}>
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{ textTransform: 'initial', fontWeight: 600, height: { xs: 'inherit', sm: '42px' } }}
                      onClick={handleOnClick}
                    >
                      Notify me!
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
