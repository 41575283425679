import React from 'react';
export const WBLogo = () => (
  <svg width="200px" height="35px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 35.029">
    <polygon points="6.672 20.548 6.672 16.696 3.336 14.77 0 16.696 0 20.548 3.336 22.474 6.672 20.548" fill="#b35cf2" />
    <polygon points="8.262 16.696 8.262 20.548 11.598 22.474 14.934 20.548 14.934 16.696 11.598 14.77 8.262 16.696" fill="#862fef" />
    <polygon points="23.196 20.548 23.196 16.696 19.86 14.77 16.524 16.696 16.524 20.548 19.86 22.474 23.196 20.548" fill="#5811c1" />
    <polygon points="19.065 13.393 19.065 9.541 15.729 7.615 12.393 9.541 12.393 13.393 15.729 15.319 19.065 13.393" fill="#862fef" />
    <polygon points="19.065 27.703 19.065 23.851 15.729 21.925 12.393 23.851 12.393 27.703 15.729 29.629 19.065 27.703"
             fill="#5811c1" />
    <polygon points="4.131 9.541 4.131 13.393 7.467 15.319 10.803 13.393 10.803 9.541 7.467 7.615 4.131 9.541" fill="#b35cf2" />
    <polygon points="4.131 23.851 4.131 27.703 7.467 29.629 10.803 27.703 10.803 23.851 7.467 21.925 4.131 23.851" fill="#862fef" />
    <polygon points="12.393 3.852 12.393 7.704 19.065 3.852 19.065 0 12.393 3.852" fill="#00ac73" />
    <polygon
      points="53.368 7.883 49.324 22.587 45.266 7.883 41.689 7.911 37.646 22.587 33.588 7.883 30.011 7.883 35.963 28.243 39.328 28.243 43.471 13.765 47.642 28.243 51.007 28.243 56.945 7.883 53.368 7.883"
      fill="#081145" />
    <path
      d="M68.333,13.659a7.1,7.1,0,0,0-4.036-1.11,7.589,7.589,0,0,0-3.959,1.018,7.026,7.026,0,0,0-2.672,2.863,9.284,9.284,0,0,0-.962,4.347,8.354,8.354,0,0,0,.983,4.1,7.1,7.1,0,0,0,2.736,2.785,8.051,8.051,0,0,0,4.072,1,7.465,7.465,0,0,0,4.036-1.145,6.84,6.84,0,0,0,2.708-3.21l-3.365-1.018a3.648,3.648,0,0,1-1.407,1.619,4.079,4.079,0,0,1-2.17.558A3.576,3.576,0,0,1,61.328,24.2a4.962,4.962,0,0,1-.97-2.644h11.05A10.7,10.7,0,0,0,70.8,16.79,6.578,6.578,0,0,0,68.333,13.659Zm-3.895,1.873a3.179,3.179,0,0,1,2.715,1.145,4.784,4.784,0,0,1,.8,2.3H60.441a4.759,4.759,0,0,1,.887-2.156A3.711,3.711,0,0,1,64.438,15.532Z"
      fill="#081145" />
    <path
      d="M86.324,15.391a4.442,4.442,0,0,0-2.3-2.178,9.022,9.022,0,0,0-3.584-.664,7.075,7.075,0,0,0-4.291,1.173A5.762,5.762,0,0,0,74,16.833l3.11.975a2.67,2.67,0,0,1,1.343-1.654,4.414,4.414,0,0,1,1.994-.481,3.279,3.279,0,0,1,2.432.75,2.865,2.865,0,0,1,.69,1.824q-.924.138-1.8.261-1.45.206-2.687.446a18.61,18.61,0,0,0-2.156.537,6.019,6.019,0,0,0-1.979,1.053,3.973,3.973,0,0,0-1.131,1.527,5.129,5.129,0,0,0-.368,1.987,4.629,4.629,0,0,0,.587,2.3,4.3,4.3,0,0,0,1.732,1.676,5.745,5.745,0,0,0,2.8.629,6.979,6.979,0,0,0,3.443-.771,6.317,6.317,0,0,0,1.916-1.709v2.056H86.9V18.869a18.185,18.185,0,0,0-.085-1.831A4.61,4.61,0,0,0,86.324,15.391Zm-3.138,8.03a3.551,3.551,0,0,1-.658,1.117,3.93,3.93,0,0,1-1.308,1,4.374,4.374,0,0,1-1.979.41,3.189,3.189,0,0,1-1.35-.254,1.935,1.935,0,0,1-.827-.686,1.772,1.772,0,0,1-.283-1A1.651,1.651,0,0,1,77,23.153a2.118,2.118,0,0,1,.629-.651,4.369,4.369,0,0,1,1-.495,13.13,13.13,0,0,1,1.4-.36q.79-.162,1.894-.346.706-.118,1.592-.259c-.006.259-.016.554-.03.888A4.374,4.374,0,0,1,83.186,23.421Z"
      fill="#081145" />
    <rect x="90.862" y="7.459" width="3.407" height="20.784" fill="#081145" />
    <path
      d="M102.979,7.459H99.586v4.97H96.814V15.1h2.772v6.906q0,1.3.028,2.333a4.553,4.553,0,0,0,.551,2.022,3.554,3.554,0,0,0,1.739,1.591,6.845,6.845,0,0,0,2.595.537,14.283,14.283,0,0,0,2.962-.247V25.387a10.942,10.942,0,0,1-2.545.113,1.972,1.972,0,0,1-1.626-.948,2.273,2.273,0,0,1-.29-1.131c-.014-.461-.021-1-.021-1.611V15.1h4.482V12.429h-4.482Z"
      fill="#081145" />
    <path
      d="M124.271,16.409a6.29,6.29,0,0,0-.961-1.867,4.952,4.952,0,0,0-1.753-1.442,6.119,6.119,0,0,0-2.8-.565,6.581,6.581,0,0,0-3.619.94,6.06,6.06,0,0,0-1.372,1.2V7.459h-3.039V28.243h3.449V20.4a7.151,7.151,0,0,1,.311-2.3,3.659,3.659,0,0,1,.828-1.414,2.938,2.938,0,0,1,1.152-.728,4.054,4.054,0,0,1,1.286-.212,3.2,3.2,0,0,1,1.93.523,3.28,3.28,0,0,1,1.075,1.336,5.747,5.747,0,0,1,.459,1.69,14.332,14.332,0,0,1,.1,1.6v7.353h3.45V19.717q0-.552-.092-1.435A9.568,9.568,0,0,0,124.271,16.409Z"
      fill="#081145" />
    <path
      d="M139.406,13.6a6.643,6.643,0,0,0-3.754-1.053A6.133,6.133,0,0,0,132,13.6c-.051.036-.1.076-.149.114V7.459h-3.435V28.243h3.011V27.117a6.134,6.134,0,0,0,.608.489,7.022,7.022,0,0,0,7.423,0,7.018,7.018,0,0,0,2.431-2.884,9.507,9.507,0,0,0,.863-4.114,9.4,9.4,0,0,0-.877-4.136A7.02,7.02,0,0,0,139.406,13.6Zm-.678,9.544a3.92,3.92,0,0,1-1.28,1.8,3.487,3.487,0,0,1-2.191.665,3.316,3.316,0,0,1-3.443-2.39,8.017,8.017,0,0,1-.389-2.615,8,8,0,0,1,.389-2.623,3.636,3.636,0,0,1,1.209-1.753,3.386,3.386,0,0,1,2.12-.629,3.659,3.659,0,0,1,2.263.671,3.918,3.918,0,0,1,1.314,1.81,7.175,7.175,0,0,1,.425,2.524A7.359,7.359,0,0,1,138.728,23.146Z"
      fill="#081145" />
    <path
      d="M156.641,13.659a7.1,7.1,0,0,0-4.037-1.11,7.588,7.588,0,0,0-3.958,1.018,7.02,7.02,0,0,0-2.672,2.863,9.272,9.272,0,0,0-.962,4.347,8.344,8.344,0,0,0,.983,4.1,7.1,7.1,0,0,0,2.735,2.785,8.054,8.054,0,0,0,4.072,1,7.466,7.466,0,0,0,4.037-1.145,6.838,6.838,0,0,0,2.707-3.21l-3.365-1.018a3.646,3.646,0,0,1-1.406,1.619,4.08,4.08,0,0,1-2.171.558,3.579,3.579,0,0,1-2.969-1.272,4.978,4.978,0,0,1-.97-2.644h11.051a10.715,10.715,0,0,0-.608-4.765A6.584,6.584,0,0,0,156.641,13.659Zm-3.895,1.873a3.179,3.179,0,0,1,2.714,1.145,4.794,4.794,0,0,1,.8,2.3h-7.509a4.77,4.77,0,0,1,.887-2.156A3.715,3.715,0,0,1,152.746,15.532Z"
      fill="#081145" />
    <path
      d="M170.079,12.909a5.963,5.963,0,0,0-1.407.269,4.856,4.856,0,0,0-1.251.6,4.128,4.128,0,0,0-1.138,1.039,5.307,5.307,0,0,0-.389.6V12.973h-3.011v15.27H166.3V20.481a6.039,6.039,0,0,1,.212-1.633,4.058,4.058,0,0,1,.658-1.351,3.479,3.479,0,0,1,1.152-.975,3.6,3.6,0,0,1,1.591-.495,4.341,4.341,0,0,1,1.562.127V12.973A6.014,6.014,0,0,0,170.079,12.909Z"
      fill="#081145" />
    <path
      d="M181.361,12.909a5.945,5.945,0,0,0-1.406.269,4.846,4.846,0,0,0-1.252.6,4.128,4.128,0,0,0-1.138,1.039,5.449,5.449,0,0,0-.389.6V12.973h-3.011v15.27h3.421V20.481a6,6,0,0,1,.213-1.633,4.037,4.037,0,0,1,.657-1.351,3.479,3.479,0,0,1,1.152-.975,3.606,3.606,0,0,1,1.591-.495,4.341,4.341,0,0,1,1.562.127V12.973A6.014,6.014,0,0,0,181.361,12.909Z"
      fill="#081145" />
    <polygon
      points="196.593 12.973 192.444 23.9 188.18 12.973 184.632 12.973 190.804 28.136 188.293 35.029 191.489 35.029 200 12.973 196.593 12.973"
      fill="#081145" />
  </svg>
);
